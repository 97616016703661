import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Event, NavigationStart, Router } from '@angular/router';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SavedJobsService } from 'src/app/services/saved-jobs.service';
import { AllJobsOrganization, Organization } from 'src/app/classes/organization.class';
import { ScrollToService } from 'src/app/services/scroll-to.service';
import { QUIZ_TYPES } from 'src/app/resources/quiz-types';
import { CandidateRegisterService } from 'src/app/services/candidate-register.service';
import { REFERER_TYPES } from 'src/app/resources/referer-types';
import { checkForQueryParams, readQueryParameters } from 'src/app/shared-functions';
import { Subject } from 'rxjs';

interface NavbarRoute {
  link: string;
  translationKey: string;
  active: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  organization: Organization = this.configService.organization;
  jobCompanyLogo: string;
  savedJobsTotal: number;

  routes: NavbarRoute[] = [];

  showApplyButton = false;
  showProgressBar = false;
  showNavigationContainer = false;
  showSocialMediaLinks = false;
  showJobListButton = false;

  showInfoButton = false;
  infoPopupActive = false;
  infoPopupContentTranslationKey = '';
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configService: AppConfigService,
    private router: Router,
    private quizStore: QuizStoreService,
    private savedJobsService: SavedJobsService,
    private cdr: ChangeDetectorRef,
    private scrollToService: ScrollToService,
    private candidateService: CandidateRegisterService
  ) {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart)
      )
      .subscribe(({ url, navigationTrigger }: NavigationStart) => {
        if (navigationTrigger === 'popstate' && (this.router.url.includes('quiz') || this.router.url.includes('video'))) {
          return;
        }

        this.setActiveRoute(url);

        this.showApplyButton = url.includes('/job-details');
        this.showJobListButton = url.includes('/application-complete');

        if (this.isAllJobs) {
          this.checkIfDisplayJobCompanyLogo(url);
        }

        this.toggleInfoButton(url);
        this.toggleProgressBar(url);
        this.toggleNavigation(url);

        if (this.infoPopupActive) {
          this.infoPopupActive = false;
        }
      });
  }

  get href(): string {
    return window.location.toString();
  }

  get isAllJobs(): boolean {
    return this.configService.isAllJobs;
  }

  get isAlternativeJob(): boolean {
    const { queryParams } = checkForQueryParams(decodeURIComponent(this.router.url));
    return queryParams['alternative-job'];
  }

  get applyEmail(): string {
    return sessionStorage.getItem('email');
  }

  get jobTitle(): string {
    return sessionStorage.getItem('jobTitle');
  }

  get applyUrl(): string {
    return sessionStorage.getItem('url');
  }

  ngOnInit(): void {
    this.trackActiveRoutes();
    this.trackSavedJobs();
    this.setNavbarRoutes();
    this.trackCompanyLogoChanges();

    if (
      this.organization.facebookProfileUrl ||
      this.organization.instagramProfileUrl ||
      this.organization.linkedInProfileUrl
    ) {
      this.showSocialMediaLinks = true;
    }
  }

  trackCompanyLogoChanges(): void {
    this.configService.jobCompanyLogo$
      .subscribe((jobCompanyLogo: string) => {
        this.jobCompanyLogo = jobCompanyLogo;
      });
  }

  checkIfDisplayJobCompanyLogo(url: string): void {
    const urlsToDisplayJobCompanyLogo = ['/job-details', '/quiz', '/video', '/register', '/application-start', '/application-complete'];
    const containsUrl = !!urlsToDisplayJobCompanyLogo.find((val: string) => url.includes(val));
    const jobCompanyLogo = sessionStorage.getItem('jobCompanyLogo');

    this.jobCompanyLogo = containsUrl && jobCompanyLogo
      ? jobCompanyLogo
      : '';
  }

  trackSavedJobs(): void {
    this.savedJobsService.savedJobsTotal$
      .subscribe(total => this.savedJobsTotal = total);
  }

  trackActiveRoutes(): void {
    this.scrollToService.activeRoute$
      .subscribe((routeLink: string) => {
        this.routes
          .forEach(({ link }, index: number, routes: NavbarRoute[]) => {
            if (link === routeLink) {
              routes[index].active = true;
              return;
            }

            routes[index].active = false;
          });
      });
  }

  setNavbarRoutes(): void {
    this.routes = [
      {
        link: 'jobs',
        translationKey: 'HEADER.ALL_JOBS',
        active: false
      }
    ];

    if (!this.configService.isAllJobs) {
      this.routes.unshift({
        link: 'home',
        translationKey: 'HEADER.HOME',
        active: false
      });
    }

    if (this.organization.useWhyWorkWithUs) {
      this.routes.push({
        link: 'why-work-with-us',
        translationKey: 'HEADER.WHY_WORK_WITH_US',
        active: false
      });
    }

    if (this.organization.allowedWhistleBlowerAlert && this.organization.whistleBlowerMetadata && this.organization.whistleBlowerMetadata.showInCompanyPresentation) {
      this.routes.push({
        link: 'whistle-blower',
        translationKey: 'HEADER.WHISTLE_BLOWER',
        active: false
      });
    }
  }

  toggleInfoButton(url: string): void {
    const quizType = this.quizStore.quiz?.type;

    this.showInfoButton = (url.includes('/quiz') && quizType !== QUIZ_TYPES.KNOCKOUT_QUESTIONS && !url.includes('personality')) ||
      url.includes('/video');
  }

  toggleProgressBar(url: string): void {
    this.showProgressBar = url.includes('/quiz') ||
      url.includes('/video') ||
      url.includes('quiz/message') ||
      url.includes('application-complete') ||
      url.includes('application-start');
  }

  toggleNavigation(url: string): void {
    this.showNavigationContainer = this.savedJobsService.checkRoutesToShowNavigationContainer(url);
  }

  toggleInfoPopup(): void {
    this.infoPopupActive = !this.infoPopupActive;

    const quizType = this.quizStore.quiz?.type;

    if (quizType === QUIZ_TYPES.PERSONALITY_TEST) {
      this.infoPopupContentTranslationKey = 'INFO_POPUP.PERSONALITY_TEST';
    } else if (quizType === QUIZ_TYPES.VIDEO_QUESTIONS) {
      this.infoPopupContentTranslationKey = 'INFO_POPUP.VIDEO_QUESTIONS';
    } else if (quizType === QUIZ_TYPES.BUSINESS_QUESTIONS) {
      this.infoPopupContentTranslationKey = 'INFO_POPUP.DEFAULT';
    }

    this.cdr.detectChanges();
  }

  setActiveRoute(url: string): void {
    if (url.includes('/jobs')) {
      this.scrollToService.setActiveRoute('jobs');
    } else if (url.includes('/why-work-with-us')) {
      this.scrollToService.setActiveRoute('why-work-with-us');
    } else if (url.includes('whistle-blower')) {
      this.scrollToService.setActiveRoute('whistle-blower');
    } else if (url.includes('home') && this.scrollToService.activeRoute !== 'home') {
      this.scrollToService.setActiveRoute('home');
    } else {
      this.scrollToService.setActiveRoute('');
    }
  }

  navigate(navToggleInput: HTMLInputElement, routeLink?: string): void {
    navToggleInput.checked = false;

    if (!routeLink) {
      return;
    }

    if (this.router.url.includes('home')) {
      this.scrollToService.setScrollTo(routeLink);

      return;
    }

    this.scrollToService.setActiveRoute(routeLink);
    this.router.navigate([routeLink]);
  }

  apply(): void {
    sessionStorage.removeItem('answers');

    const guid = readQueryParameters('job') || readQueryParameters('alternative-job');

    if (this.configService.referrer && this.configService.organization.isAllJobs) {
      this.candidateService
        .submitReferer(`${REFERER_TYPES.INTERNAL_JOB_PLATFORM_APPLY}-${this.configService.domain}`, this.configService.referrer, guid)
        .pipe(
          takeUntil(this._ngUnsubscribe$)
        )
        .subscribe();
    }
  }

  logoNavigate(): void {
    if (this.isAllJobs) {
      if ((this.organization as AllJobsOrganization).logoLink && this.router.url.includes('jobs')) {
        window.location.href = (this.organization as AllJobsOrganization).logoLink;
      } else {
        this.router.navigate(['/jobs']);
      }
    } else {
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
